import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import './styles.css';

interface LoadingIndicatorProps {
  overlay?: boolean;
  size?: any;
  message?: string;
  visible?: boolean;
}

const LoadingIndicator = ({
  overlay = true,
  visible = true,
  size,
  message,
}: LoadingIndicatorProps) => (
  <div
    className={`loader-container ${overlay ? 'overlay' : ''} ${
      visible ? 'show' : 'hide'
    }`}>
    <Spin indicator={<LoadingOutlined spin />} size={size} />
    {message && <span>{message}</span>}
  </div>
);

export default LoadingIndicator;
