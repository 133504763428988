import React from 'react';
import {Button} from 'antd';

interface PrimaryButtonProps {
  label?: string;
  htmlType?: any;
  disabled?: boolean;
  children?: React.ReactNode;
  style?: object;
  ghost?: boolean;
  icon?: React.ReactNode;
  onClick?: any;
  props?: object;
  loading?: boolean;
}

const PrimaryButton = ({
  label,
  disabled,
  children,
  style,
  icon,
  onClick,
  loading,
  htmlType,
  ...props
}: PrimaryButtonProps) => {
  return (
    <Button
      disabled={disabled}
      type="primary"
      shape="round"
      style={{...style}}
      icon={icon}
      htmlType={htmlType}
      loading={loading}
      onClick={onClick}
      {...props}>
      {label || children}
    </Button>
  );
};

export default PrimaryButton;
