import {RightOutlined} from '@ant-design/icons';
import {Form, Input, Modal} from 'antd';
import axios from 'axios';
import React, {useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import PrimaryButton from '../../../CommonUI/PrimaryButton';

const ChangePassword = ({user, code}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const changePassword = ({password}) => {
    setLoading(true);
    axios
      .post(`auth/recovery/complete`, {code, email: user.email, password})
      .then(() => {
        setLoading(false);
        history.push('/login');
      })
      .catch(error => {
        setLoading(false);
        let message = error.message;
        if (error.response) {
          message = error.response.data.status.msg;
        }

        Modal.error({
          content: message,
          okText: 'try again',
          centered: true,
        });
      });
  };

  return (
    <div>
      <p>Hi! {user.name}, please type your new password.</p>
      <Form initialValues={{remember: true}} onFinish={changePassword}>
        <Form.Item
          name="password"
          rules={[{required: true, message: 'error.required'}]}>
          <Input.Password placeholder={'password'} />
        </Form.Item>
        <div className={'recover-button-control'}>
          <PrimaryButton loading={loading} htmlType="submit">
            {'Change password'}
            <RightOutlined />
          </PrimaryButton>
        </div>
        <p style={{marginTop: 20, textAlign: 'center'}}>
          <Link to={'/login'}>{'Back to login'}</Link>
        </p>
      </Form>
    </div>
  );
};

export default ChangePassword;
