import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

//import reportWebVitals from './reportWebVitals';
import './index.less';

import CodeVerification from './Authentication/Screens/CodeVerification';
import {AuthContextProvider} from './Context/AuthContext';
import LoadingIndicator from './CommonUI/LoadingIndicator';
import Browser from './Utils/Browser';

const App = React.lazy(() => import('./App/App'));
const Login = React.lazy(() => import('./Authentication/Screens/Login'));
const Recover = React.lazy(
  () => import('./Authentication/Screens/RecoverAccount'),
);
const PublicPackageSearch = React.lazy(
  () => import('./PublicFreeZones/Screens/PackageSearch'),
);

function gotoLogin() {
  if (!window.location.href.includes('public')) {
    if (window.location.pathname.indexOf('/login') === -1) {
      window.location.href = '/login';
    }
  }
}

const token = Browser.getCookie('token');
axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.withCredentials = true;
if (token) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token;
} else {
  gotoLogin();
}
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  error => {
    if (error.message !== 'Network Error' && error.response) {
      switch (error.response.status) {
        case 401:
          gotoLogin();
          break;
        default:
          return Promise.reject(error);
      }
    }
  },
);

ReactDOM.render(
  <AuthContextProvider>
    <BrowserRouter>
      <React.Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/recover" component={Recover} />
          <Route path="/change-password" component={Login} />
          <Route
            exact
            path="/verification/:email"
            component={CodeVerification}
          />
          <Route
            path={'/public/package-search'}
            component={PublicPackageSearch}
          />
          <Route path="/" component={App} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  </AuthContextProvider>,
  document.getElementById('root'),
);

// @ts-ignore
window.Pusher = require('pusher-js');
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
