import React, {useState, useEffect} from 'react';
import {Form, Input, Modal, notification} from 'antd';
import {BarcodeOutlined, RightOutlined, MailOutlined} from '@ant-design/icons';
import {Link, useParams} from 'react-router-dom';
import axios from 'axios';

import './styles.css';
import PrimaryButton from '../../../CommonUI/PrimaryButton';
import ChangePassword from '../../Components/ChangePassword';

const logo =
  require(`../../../images/logo_${process.env.REACT_APP_CODE}.png`).default;
const bg =
  require(`../../../images/bg_${process.env.REACT_APP_CODE}.jpg`).default;

const CodeVerification = () => {
  const {email} = useParams();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [code, setCode] = useState();
  const [parsedEmail, setParsedEmail] = useState();

  useEffect(() => {
    try {
      setParsedEmail(atob(email));
    } catch (e) {
      notification.error({description: e.message});
    }
  }, [email]);

  const requestRecover = ({code}) => {
    setLoading(true);
    setCode(code);
    axios
      .post(`auth/recovery/verify`, {code, email: parsedEmail})
      .then(({data}) => {
        setLoading(false);
        setUser(data.data);
      })
      .catch(error => {
        setLoading(false);
        let message = error.message;
        if (error.response) {
          message = error.response.data.status.msg;
        }

        Modal.error({
          content: message,
          okText: 'try again',
          centered: true,
        });
      });
  };

  const rules = {
    email: [{required: true, message: 'error.required'}],
    code: [{required: true, message: 'error.required'}],
  };

  return (
    <div
      className={'recover-wrapper'}
      style={{backgroundImage: 'url(' + bg + ')'}}>
      <div className={'recover-block'}>
        <div>
          <img src={logo} alt={process.env.REACT_APP_NAME} className="logo" />
        </div>
        {user ? (
          <ChangePassword user={user} code={code} />
        ) : (
          <>
            <p>{'verification_message'}</p>
            <Form
              name="code_verification"
              initialValues={{remember: true}}
              onFinish={requestRecover}>
              {parsedEmail ? (
                <p>{parsedEmail}</p>
              ) : (
                <Form.Item name="email" rules={rules.code}>
                  <Input
                    prefix={<MailOutlined />}
                    placeholder={'email'}
                    className={'recover-input'}
                  />
                </Form.Item>
              )}
              <Form.Item name="code" rules={rules.code}>
                <Input
                  prefix={<BarcodeOutlined />}
                  placeholder={'code'}
                  className={'recover-input'}
                />
              </Form.Item>

              <div className={'recover-button-control'}>
                <PrimaryButton loading={loading} htmlType="submit">
                  {'Recover password'}
                  <RightOutlined />
                </PrimaryButton>
              </div>
              <p style={{marginTop: 20, textAlign: 'center'}}>
                <Link to={'/login'}>{'Back to login'}</Link>
              </p>
            </Form>
          </>
        )}
      </div>
    </div>
  );
};

export default CodeVerification;
