import React, {createContext, useState, useEffect} from 'react';
import axios from 'axios';

import Browser from '../Utils/Browser';
import {User} from '../Types/api';

interface AuthContextDefaults {
  user: User | null;
  setUser: (data: any) => void;
  logout: () => Promise<any>;
  reloadSessionData: () => void;
}

interface AuthContextProp {
  children?: React.ReactNode;
}

const AuthContext = createContext<AuthContextDefaults>({
  logout(): Promise<any> {
    return Promise.resolve(undefined);
  },
  reloadSessionData(): void {},
  setUser(): void {},
  user: null,
});

const AuthContextProvider = ({children}: AuthContextProp) => {
  const [user, setUser] = useState<User | null>(null);
  const [reloadSession, setReloadSession] = useState<boolean>(false);

  useEffect(() => {
    const token = Browser.getCookie('token');
    if (token) {
      axios
        .get('auth/session')
        .then(response => {
          setUser(response.data?.data);
        })
        .catch(() => {});
    }
  }, [reloadSession]);

  const logout = async () => {
    await axios.get('auth/logout');
    setUser(null);
    Browser.deleteCookies('token');
    window.location.href = '/login';
  };

  const reloadSessionData = () => {
    setReloadSession(!reloadSession);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        logout,
        reloadSessionData,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export {AuthContextProvider};
export default AuthContext;
